#hero{
    background-color: $secondary;
    @extend .background-image;
    background-image: url(../../images/hero-bcg.png);
    padding-top: 5rem;
    color: #2d272f;

    @include mobile{
        background-position: 60% center;
        padding-bottom: 2rem;
        padding-top:6rem;

    }
    @include mobile-portrait{
        background-image: url(../../images/hero-bcg-mobile.png);
        background-position: center bottom;
        background-size: cover;
        background-color: #fec316;
    }

    .container{
        padding-top: 1rem;
        padding-bottom: 4rem;
    }

    h3{
        @include mobile{
            font-size: 1.6rem;
        }
        @include mobile-portrait{
            @include fluid-type(20px, 30px, 320px, 565px);
        }
    }

    h1{
        text-transform: uppercase;
        font-weight: 700;
        margin: 2rem 0 0;

        @include mobile-portrait{
            @include fluid-type(30px, 45px, 320px, 565px);
        }
    }
    .button-wrapper{
        padding-top:3rem;
        
    }
    .button.button-full{
        width:14rem;

        @include mobile-portrait{
            width: 50%;
        }
    }
}