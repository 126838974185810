.button,
.button:hover,
.button:active,
.button:focus{
    outline: none !important;
    background-color: transparent;
    border: none;
    font-family: $font-primary;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 1rem 2rem;

    @include mobile{
      padding:1.5rem 2.5rem;
    }
}

.button-full,
.button-full:hover,
.button-full:focus,
.button-full:active
{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: white;
    background-color: $primary;
    border-radius: 10px;
    width: 16.5rem;
    max-width: 100%;
    font-weight: 400;
    line-height: 1;
    overflow: hidden;
    z-index: 1;

    @include mobile{
      font-size: 1.2rem;
      width:auto;
    }

    &:focus{
        outline: 0;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.5);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        opacity: .5;
        filter: blur(30px);
        transform: translateX(-100px)  skewX(-15deg);
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.2);
        width: 30px;
        height: 100%;
        left: 30px;
        top: 0;
        opacity: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
      }
      &:hover{
        background: black;
        cursor: pointer;
        &:before{
          transform: translateX(300px)  skewX(-15deg);  
          opacity: 0.6;
          transition: .7s;
        }
        &:after{
          transform: translateX(300px) skewX(-15deg);  
          opacity: 1;
          transition: .7s;
        }
      }

}

@mixin center($extend: true) {
    @if $extend {
        @extend %center;
    } @else {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.button:hover{
    background-color: $primary;
    color:white;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.05);
}

.button:active{
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 2px 3px 5px 0 rgba(0, 0, 0, 0.03);
}



