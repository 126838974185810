@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');


@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-UltraLight_0.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Thin_0.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Light_0.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Regular_0.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Medium_0.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-SemiBold_0.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Bold_0.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Black_0.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Gilroy';
    src: URL('../../fonts/Gilroy-Heavy_0.ttf') format('truetype');
    font-weight: 900;
}
