.background-image{
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
}

.smaller-font{
    font-size: 0.9rem;
}


@media (max-height: 750px){
    .smaller-font{
        font-size: 13px;
    }
}

@media (max-width: 1199px){
    .smaller-font{
        font-size: 13px;
    }
}