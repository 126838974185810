.hamburger{
    position: absolute;
    right: 0;
    padding:23px 25px;
    top:0;
    
    .hamburger-box{
        width: 26px;

        .hamburger-inner{
            width: 26px;

            &:before{
                // top: -8px;
                width: 14px;
                right: 0;
            }

            &:after{
                bottom: -8px;
                width: 20px;
                right: 0;
            }
        }
    }

    &.is-active{
        .hamburger-box{    
            .hamburger-inner{
                width: 26px;
                margin-left: 0;

                transition: transform .15s cubic-bezier(.645,.045,.355,1),background-color 0s cubic-bezier(.645,.045,.355,1);

                &:before{
                    width: 26px;
                }

                &:after{
                    bottom: -10px;
                    width: 26px;
                }
            }
        }
    }

    &:hover{
        opacity: 1;
    }
}

.hamburger .hamburger-inner,
.hamburger .hamburger-inner:after, 
.hamburger .hamburger-inner:before{
    background-color: #a6a6a6 !important;
    height: 4;
}

@media (max-width: 767px){
    .register-container .hamburger{
        display: block !important;
    }
}