#section3{
    padding: 0 0 6rem;
    overflow: visible;

    @include bigtablet-down{
        padding-bottom: 3rem;
    }
    h3{
        font-weight: 700;
        text-transform: uppercase;
    }
    h4{
        font-weight: 400;
        font-family: $font-primary;
        margin-bottom: 0.5rem;
    }
    p{
        margin: 0;
        font-size: 0.9rem;
        color: #929292;

        a{
            color: inherit;
            text-decoration: underline;
        }

        @media (max-width: 1199px){
            font-size: 1rem;
        }
    }
    .grey-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgb(244,244,244);
        background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 100%);
        background-image: url(../../images/section3-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding:2rem 4rem;
        border-radius: 10px;

        @include bigtablet-down{
            padding-right:0;
        }
        @include smalltablet-down{
            padding-left:2rem;
        }

        .right-side{
            text-align: right;
        }

        .left-side{
            position: relative;

            .image-wrapper{
                position: absolute;
                left:0;
                top:0;
                transform: translateY(-50%);

                img{
                    width: auto;
                    max-width: 18rem;

                    @include smalltablet-down{
                        max-width: 16rem;
                    }
                }
            }
        }

        @include mobile{
            background:transparent;
            flex-flow: column-reverse;
            padding:0;

            h3, h4{
                line-height: 1.2;
            }

            .left-side{
                width: 100%;
                position: relative;
                padding:0 1rem;
                margin-top:3rem;

                &:before{
                    height: 70%;
                    width:100%;
                    transform: translateY(-50%);
                    top:50%;
                    left:0;
                    content:"";
                    background: rgb(244,244,244);
                    background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 100%);
                    background-image: url(../../images/section3-bg.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: absolute;
                    border-radius: 10px;
                }

                .image-wrapper{
                    position: relative;
                    transform: none;

                    img{
                        max-width: 80%;
                        width:20rem;
                    }
                }
            }

            .right-side{
                text-align: center;
                width: 100%;
            }
        }
    }
}