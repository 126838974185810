@keyframes fadeIn {
    from {
        opacity: 0;
        box-shadow: 0 0 0px 0px rgba(0,0,0,0);
        visibility: hidden;
    }
    
    to {
        visibility: visible;
        opacity: 1;
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.10);
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.10);
        visibility: visible;
    }

    to {
        opacity: 0;
        box-shadow: 0 0 0px 0px rgba(0,0,0,0);
        visibility: hidden;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

.animated {
    -webkit-animation-duration: .15s;
    animation-duration: .15s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); 
}