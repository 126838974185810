#section1{
    background-color: #0a0704;
    color: #ffffff;
    padding:3rem 0 2rem;

    @include mobile{
        padding-bottom: 8rem;
    }

    h2{
        margin-bottom: 1rem;

        @include mobile-portrait{
            @include fluid-type(26px, 32px, 320px, 565px);
        }
    }

    .container{
        display: flex;

        .left-side{
            width: 57%;

            .graph-wrapper{
                .graph{
                    position: relative;
                    overflow: hidden;

                    img{
                        max-width: 35rem;

                       

                        @include smalltablet-down{
                            max-width: 26rem;
                        }
                    }
                }
                .date-wrapper{
                    display: flex;
                    justify-content: space-between;
                    font-weight: 300;
                    padding:0.5rem 0;
                    font-size: 13px;
                    max-width: 35rem;
                    font-family: $font-secondary;

                    @include big-desktop-up{
                        max-width: 615px;
                    }

                    @include smalltablet-down{
                        max-width: 26rem;
                    }

                    @include mobile{
                        padding-top:1rem;
                        padding-bottom: 1rem;
                    }
                }
                .graph-line{
                    position: absolute;
                    bottom:5%;
                    left:0;
                    width:100%;
                    left: -10px;
                    overflow: hidden;
                    
                    transition: width 1.6s ease-in-out;

                    @include mobile{
                        left: -5px;
                    }

                    &.visible{
                        width: 100%;
                    }

                    &[data-aos="grow"]{
                        width: 0;

                        &.aos-animate {
                            width:100%;
                          }
                    }

                    img{
                        max-width: 34.5rem;

                        @include smalltablet-down{
                            max-width: 26rem;
                        }
                 
                    }
                }
            }
        }
        .right-side{
            width: 43%;
            padding-left:0;
            text-align: center;
            display: flex;
            flex-flow: column;
            padding-top:4rem;

            h2{
                text-transform: uppercase;
            }
            h4{
                font-weight: 300;
            }
        }

        @include mobile{
            flex-flow: column-reverse;
            

            .left-side{
                width: 100%;

                .graph-wrapper{
                    .graph{
                      display: table;
                      margin-left: auto;
                      margin-right: auto;
                    }
                }
                .date-wrapper{
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .right-side{
                width: 100%;
                padding-top:1rem;
                padding-bottom: 3rem;
            }
        }

        @include mobile-portrait{
            .left-side{
                .graph-wrapper{
                    .graph{
                        width:calc(100vw - 50px);
                        max-width: none;

                        img{
                            width:calc(100vw - 50px);
                            max-width: none;
                        }
                    }
                    .date-wrapper{
                        width:calc(100vw - 50px);
                        max-width: none;
                    }
                }
            }
        }
    }
}