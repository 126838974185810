.header{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    transition: all 600ms ease-in-out;
}

.nav{
    height: 5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    .logo{
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .website-url{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
        
        img,
        svg{
            height: 50%;
            max-height: 45px;
            width: auto;
            transition: all .15s ease-in-out;
        }
        
    }

    .button{
        margin-top: 0.2rem;
        font-size: 0.9rem;
        font-family: $font-secondary;
        font-weight: 500;
        color: $header-button-color;
        padding: 1rem;
        margin-right: -1rem;
        text-transform: uppercase;

        &:hover{
            color: $primary !important;
        }
    }
}

.menu{
    display: flex;
    align-items: center;
}

.language-selector{
    position: relative;

    img{
        height: 15px;
        width: auto;
        margin-right: 1rem;
    }

    svg{
        fill: $header-button-color;
        height: 8px;
        transition: all .15s ease-in-out;
    }

    .selected-language{
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        padding: 1rem;
        cursor: pointer;
    }

    .language-options{
        position: absolute;
        z-index: 2;
        top: 0;
        padding: 45px 0 10px 0;
        transition: all .15s ease-in-out;
        visibility: hidden;

        li{
            display: flex;
            justify-content: flex-start;
            opacity: 0;
            transition: all .15s ease-in-out;
            margin-bottom: 0;

            a{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                box-sizing: border-box;
                padding: 5px 1rem;
                transition: all .15s ease-in-out;

                span{
                    transition: all .15s ease-in-out;
                    color: $header-button-color;
                    margin-left: -5px;
                }
            }

            &:hover{
                background-color: $language-option-hover-background;
            }
        }
    }

    &:hover{
        .language-options{
            background-color: $language-options-background;
            box-shadow: $language-options-shadow;
            visibility: visible;

            li{
                opacity: 1;

                a{
                    padding: 10px 1rem;
                }

                &:hover{
                    a{
                        span{
                            color: $primary !important;
                        }
                    }
                }

            }
        }
    }
}

.hamburger{
    display: none;
}

.choose-language{
    display: none;
}

.header.light{
    &:after{
        border-color: $header-light-border-color;
    }

    .nav{
        &:after{
            border-color: $header-light-border-color;
        }

        .logo{
            svg{
                path{
                    fill: white;
                }
            }
        }

        .button{
            color: $header-light-button-color;
        }
    }

    .language-selector{
        svg{
            fill: $header-light-button-color;
        }

        .language-options{
            li{
                a{
                    span{
                        color: $header-light-button-color;
                    }
                }

                &:hover{
                    background-color: $language-option-light-hover-background;
                }
            }
        }

        &:hover{
            .language-options{
                background-color: $language-options-light-background;
                box-shadow: $language-options-light-shadow;
            }
        }
    }

}

.header.header-border{
    &:after{
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        border-top: solid 1px $header-border-color;        
    }
}

.header.header-border-side{
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        border-top: solid 1px $header-border-color;
        width: calc(50% - 555px);
    }

    nav{
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            width: calc(100% - 30px);
            border-top: solid 1px $header-border-color;
            
        }
    }

    &.left{
        &:after{
            left: 0;
        }
    }

    &.right{
        &:after{
            right: 0;
        }
    }
}

@media (max-width: 479px){
    
}
@media (min-width: 480px){
    

}

@media (min-width: 480px) and (max-width: 767px){
    
}

@media (max-width: 767px){
    .header.header-border-side{
        &:after{
            width: 100%;
        }
    }
    .header.header-border-side{
        .nav{
            &:after{
                display:none;
            }
        }
    }
}


@media (min-width: 768px){
    
}

@media (min-width: 768px) and (max-width: 991px){
    .header.header-border-side{
        &:after{
            width: calc(50% - 355px);
        }
    }
}

@media (max-width: 991px){
    
}


@media (min-width: 992px){
    
}

@media (min-width: 992px) and (max-width: 1023px){
    .header.header-border-side{
        &:after{
            width: calc(50% - 475px);
        }
    }
}


@media (max-width: 1023px){
    
}


@media (min-width: 1024px){
    
}

@media (min-width: 1024px) and (max-width: 1199px){
    .header.header-border-side{
        &:after{
            width: calc(50% - 465px);
        }
    }
}

@media (min-width: 1024px) and (max-width: 1199px) and (max-height: 799px){
    
}

@media (min-width: 1024px) and (max-width: 1199px) and (min-height: 800px){
    
}

@media (max-width: 1199px){
    
}


@media (min-width: 1200px){
    
}

@media (min-width: 1200px) and (max-width: 1399px){
    
}
@media (min-width: 1200px) and (max-width: 1399px) and (max-height: 799px){
    
}

@media (min-width: 1200px) and (max-width: 1399px) and (min-height: 800px){
    
}

@media (max-width: 1399px){

}


@media (min-width: 1600px){
    
}

@media (min-width: 1400px) and (max-height: 799px){
    
}

@media (min-width: 1400px) and (min-height: 800px) and (max-height: 899px){
    
}

@media (min-width: 1400px) and (min-height: 900px){
    
}


// based on rem

@media (max-height: 799px){

}

@media (min-height: 800px) and (max-height: 899px){

}

@media (min-height: 900px){

}

@media (max-width: 1023px){

}

@media (min-width: 1024px) and (max-width: 1399px){

}

@media (min-width: 1400px){

}