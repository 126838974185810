#section2{
    padding-top: 6rem;
    padding-bottom:  6rem;
    margin-top:-10rem;

    @include bigtablet-down{
        margin-top: 0;
        padding:3rem 0;
    }

    @include mobile{
        margin-top:-8rem;
    }

    .container{
        display: flex;
        justify-content: space-between;

        .left-side{
            flex-grow: 1;
            padding-right:2rem;
            padding-top:12rem;

            @include bigtablet-down{
                padding-top:0;
            }

            h3{
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 3rem;
            }

            ul.why-gold{
                max-width: 33rem;
                li{
                    position: relative;
                    padding-left:2rem;
                    line-height: 1.8;
                    font-size: 1.1rem;

                    @include mobile{
                        line-height: 1.5;
                        margin-bottom: 0.5rem;
                    }

                    &:before{
                        content:"";
                        position: absolute;
                        left:0;
                        top:0.5rem;
                        width:1rem;
                        height: 0.7rem;
                        background-image: url(../../images/ic-check.png);
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;

                        @include mobile{
                            top:0.6rem;
                        }
                    }
                }
            }
        }

        .right-side{
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }

        @include mobile{
            flex-flow: column-reverse;

            .left-side{
                width: 100%;
                padding-right: 0;
                padding-top:4rem;

                h3{
                    text-align: center;
                    margin-bottom: 2rem;
                }
            }

            .right-side{
                width:100%;
                
            }
        }
    }
}