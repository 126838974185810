// MEDIA QUERIES
@mixin mobile{
    @media (max-width: 767px) { @content; }
}
@mixin tablet-down{
    @media (max-width: 1023px) { @content; }
}
@mixin smalltablet-down{
    @media (max-width: 991px) { @content; }
}
@mixin bigtablet-down{
    @media (max-width: 1199px) { @content; }
}
@mixin mobile-portrait{
    @media (max-width: 575px) { @content; }
}


@mixin tablet-up{
    @media (min-width: 768px) { @content; }
}
@mixin tablet-landscape-up {
    @media (min-width: 1024px) { @content; }
}
@mixin desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin big-desktop-up {
    @media (min-width: 2000px) { @content; }
}


@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
    font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range){
        font-size: $max-font-size;
    }
}